
import React from 'react';
import WebBase from "./WebLayout/WebBase";
import {NavLink} from 'react-router-dom'
const AboutUs = () => {
    return (
        <>
         <WebBase>
          <section className="career-pg">
                <div className="container">
                    <div className="row">

                    <div className="col-lg-12 pt-5 pb-3">
                            <p className="text-center pb-3">
                              <b>Yolojet: The Smart Shipping Solution with Heart for Growing Businesses ❤️</b><br></br>
                            </p>
                            <p>
Yolojet is a tech-driven logistics platform tailored for <b>SMEs</b> and <b>startups</b>, offering reliable and scalable shipping solutions. <br></br>
We enable businesses to compare prices and delivery times from over 12 leading logistics companies across <b>27,000+ PIN codes</b>,<br></br> ensuring you find the perfect match for your needs, whether cost or speed is your priority. <br></br><br></br>
 
At Yolojet, <b>care and compassion</b> drive everything we do. We understand that every escalation represents a challenge for <br></br> your business, and we respond with genuine <b>understanding and dedication</b> to resolve it.<br></br><br></br>
 
Our vision is to <b>support budding businesses</b> with seamless logistics, so they can focus on growth. We strive to be <br></br> the <b>preferred partner</b> across industries and use cases by leveraging our deep understanding of <b>on-ground operations</b> and <br></br> the <b>strategic goals</b> of your company. <br></br>
With Yolojet, you can:
                            </p>
                            <ul>
                                <li>Seamlessly manage <b>B2B, B2C/D2C, cross-border</b>, and <b>international shipments</b>.</li>
                                <li>Use a unified dashboard for easy shipment tracking.</li>
                                <li>Count on <b>dedicated account managers</b> who handle your issues with real concern.</li>
                                <li>Benefit from <b>hyper-local deliveries</b> and flexible options like <b>Prepaid and COD</b>.</li>
                                <li>Track shipments in real-time and manage pickups from multiple locations.</li>
                                <li>Use our <b>analytics-powered recommendation engine</b> to optimize your logistics.</li>

                        </ul>
                        <br></br>
                        <br></br>
                        <p>
                        Yolojet delivers not just shipments, but <b>peace of mind</b>—so you can focus on growing your business.
                        </p>
                        <br></br>
                        
                        <p><b>Call us at 8882780086</b> or send some ❤️at <b>hello@yolojet.com</b> to learn how we can be your trusted logistics partner!</p>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                    </div>

                        {/* <div className="col-lg-12 pt-5 pb-3">
                            <p>Yolojet is an innovative technology powered, automated shipping solution for sellers.Our solution enables you tocompare among leading logistic companies with regards to their price and time of delivery between specified source and destination pin codes. You can Select the best for you based on your preferences ofshipment costor the time of delivery for your shipment, in just a click.  Yolojet’s free to use platform comes with host of benefits along with following services/solutions, to ensure that your shipments issues are the last thing to worry about for you.</p>
                            <ul>
                                <li>27000+ Serviceable Pin Codes</li>
                                <li>More than 12 leading logistic companies to choose from</li>
                                <li>Discounted Shipping Rates</li>
                                <li>Freedom to compare and choose basis your preference of price and TAT</li>
                                <li>Dashboard to give you unified view of your shipment</li>
                                <li>Dedicated account manager</li>
                                <li>Hyper-local Delivery</li>
                                <li>Our analytics powered Recommendation Engine to help you choose the best</li>
                                <li>Flexibility of shipping your consignment on both Prepaid &amp; Cash on Delivery modes</li>
                                <li>Option of both individual and Bulk order upload</li>
                                <li>Tracking of your shipment</li>
                                <li>Multiple Pickup Locations</li>
                                <li>Professional customer service and dedicated NDR and Dispute management team</li>
                                <li>Quick and easy onboarding process.</li>
                        </ul>
                        <div className="text-center pt-4">
                            <NavLink to="/Career" className="btn btn-primary">Apply Now</NavLink>

                        </div>
                        </div> */}
                    </div>
                </div>
            </section>
        
         </WebBase>
        </>
    )
}

export default AboutUs
