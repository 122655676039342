const ACTIONS = {
    GET_LOGGEDIN_USER: 'GET_LOGGEDIN_USER',
    GET_LOGGEDIN_USER_SUCCESS: 'GET_LOGGEDIN_USER_SUCCESS',
    GET_LOGGEDIN_USER_FAIL: 'GET_LOGGEDIN_USER_FAIL',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    UPDATE_USER_REQUEST: 'UPDATE_LOGGEDIN_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'UPDATE_LOGGEDIN_USER_SUCCESS',
    UPDATE_USER_FAIL: 'UPDATE_LOGGEDIN_USER_FAIL',

    ADD_ADDRESS_REQUEST : 'ADD_ADDRESS_REQUEST',
    ADD_ADDRESS_SUCCESS: 'ADD_ADDRESS_SUCCESS',
    ADD_ADDRESS_FAIL: 'ADD_ADDRESS_FAIL',

    UPDATE_ADDRESS_REQUEST: 'UPDATE_ADDRESS_REQUEST',
    UPDATE_ADDRESS_SUCCESS: 'UPDATE_ADDRESS_SUCCESS',
    UPDATE_ADDRESS_FAIL: 'UPDATE_ADDRESS_FAIL',
    
}
export default ACTIONS