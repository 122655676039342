const ACTIONS = {
    CREATE_BULK_ORDER_REQUEST: "CREATE_BULK_ORDER_REQUEST",
    CREATE_BULK_ORDER_SUCCESS: "CREATE_BULK_ORDER_SUCCESS",
    CREATE_BULK_ORDER_FAIL: "CREATE_BULK_ORDER_FAIL",

    DOWNLOAD_ALL_INVOICE_ORDERS_REQUEST: "DOWNLOAD_ALL_INVOICE_ORDERS_REQUEST",
    DOWNLOAD_ALL_INVOICE_ORDERS_SUCCESS: "DOWNLOAD_ALL_INVOICE_ORDERS_SUCCESS",
    DOWNLOAD_ALL_INVOICE_ORDERS_FAIL: "DOWNLOAD_ALL_INVOICE_ORDERS_FAIL",

    SEARCH_ALL_ORDERS_REQUEST: "SEARCH_ALL_ORDERS_REQUEST",
    FETCH_ALL_INVOICE_ORDERS_REQUEST: "FETCH_ALL_INVOICE_ORDERS_REQUEST",
    FETCH_ALL_ORDERS_REQUEST: "FETCH_ALL_ORDERS_REQUEST",
    FETCH_ALL_ORDERS_SUCCESS: "FETCH_ALL_ORDERS_SUCCESS",
    FETCH_ALL_ORDERS_FAIL: "FETCH_ALL_ORDERS_FAIL",

    FETCH_ORDER_REQUEST: "FETCH_ORDER_REQUEST",
    FETCH_ORDER_SUCCESS: "FETCH_ORDER_SUCCESS",
    FETCH_ORDER_FAIL: "FETCH_ORDER_FAIL",

    FETCH_ALL_BULK_ORDERS_REQUEST: "FETCH_ALL_BULK_ORDERS_REQUEST",
    FETCH_ALL_BULK_ORDERS_SUCCESS: "FETCH_ALL_BULK_ORDERS_SUCCESS",
    FETCH_ALL_BULK_ORDERS_FAIL: "FETCH_ALL_BULK_ORDERS_FAIL",

    FETCH_BULK_ORDER_REQUEST: "FETCH_BULK_ORDER_REQUEST",
    FETCH_BULK_ORDER_SUCCESS: "FETCH_BULK_ORDER_SUCCESS",
    FETCH_BULK_ORDER_FAIL: "FETCH_BULK_ORDER_FAIL",

    TRACK_ORDER_REQUEST: "TRACK_ORDER_REQUEST",
    TRACK_ORDER_SUCCESS: "TRACK_ORDER_SUCCESS",
    TRACK_ORDER_FAIL: "TRACK_ORDER_FAIL",

    CANCEL_ORDER_REQUEST: "CANCEL_ORDER_REQUEST",
    CANCEL_ORDER_SUCCESS: "CANCEL_ORDER_SUCCESS",
    CANCEL_ORDER_FAIL: "CANCEL_ORDER_FAIL",

    CREATE_RTO_ORDER_REQUEST: "CREATE_RTO_ORDER_REQUEST",
    CREATE_RTO_ORDER_SUCCESS: "CREATE_RTO_ORDER_SUCCESS",
    CREATE_RTO_ORDER_FAIL: "CREATE_RTO_ORDER_FAIL",

    ORDER_PAY_REQUEST: "ORDER_PAY_REQUEST",
    ORDER_PAY_SUCCESS: "ORDER_PAY_SUCCESS",
    ORDER_PAY_FAIL: "ORDER_PAY_FAIL",

    SELECT_PARTNER: "SELECT_PARTNER",

    SELECT_ORDER: "SELECT_ORDER",

    RAISE_DISPUTE_REQUEST: "RAISE_DISPUTE_REQUEST",
    RAISE_DISPUTE_SUCCESS: "RAISE_DISPUTE_SUCCESS",
    RAISE_DISPUTE_FAIL: "RAISE_DISPUTE_FAIL",

    DISPUTE_SETTLE_REQUEST: "DISPUTE_SETTLE_REQUEST",
    DISPUTE_SETTLE_SUCCESS: "DISPUTE_SETTLE_SUCCESS",
    DISPUTE_SETTLE_FAIL: "DISPUTE_SETTLE_FAIL",

    // FETCH_ALL_COD_REPORTS_REQUEST: "FETCH_ALL_COD_REPORTS_REQUEST",
    // FETCH_ALL_COD_REPORTS_SUCCESS: "FETCH_ALL_COD_REPORTS_SUCCESS",
    // FETCH_ALL_COD_REPORTS_FAIL: "FETCH_ALL_COD_REPORTS_FAIL",
};

export default ACTIONS;
