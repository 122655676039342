const ACTIONS = {
    CREATE_REQUEST: "CREATE_USER_REQUEST",
    CREATE_SUCCESS: "CREATE_USER_SUCCESS",
    CREATE_FAIL: "CREATE_USER_FAIL",

    FETCH_REQUEST: "FETCH_USER_REQUEST",
    FETCH_SUCCESS: "FETCH_USER_SUCCESS",
    FETCH_FAIL: "FETCH_USER_FAIL",

    FETCH_ALL_REQUEST: "FETCH_ALL_USERS_REQUEST",
    FETCH_ALL_SUCCESS: "FETCH_ALL_USERS_SUCCESS",
    FETCH_ALL_FAIL: "FETCH_ALL_USERS_FAIL",

    UPDATE_REQUEST: "UPDATE_USER_REQUEST",
    UPDATE_SUCCESS: "UPDATE_USER_SUCCESS",
    UPDATE_FAIL: "UPDATE_USER_FAIL",

    DELETE_REQUEST: "DELETE_USER_REQUEST",
    DELETE_SUCCESS: "DELETE_USER_SUCCESS",
    DELETE_FAIL: "DELETE_USER_FAIL",

    FETCH_ALL_USAGE_REQUEST: "FETCH_ALL_USAGE_REQUEST",
    FETCH_ALL_USAGE_SUCCESS: "FETCH_ALL_USAGE_SUCCESS",
    FETCH_ALL_USAGE_FAIL: "FETCH_ALL_USAGE_FAIL",

    FETCH_USER_USAGE_REQUEST: "FETCH_USER_USAGE_REQUEST",
    FETCH_USER_USAGE_SUCCESS: "FETCH_USER_USAGE_SUCCESS",
    FETCH_USER_USAGE_FAIL: "FETCH_USER_USAGE_FAIL",

    FETCH_ALL_CUSTOMER_REQUEST: "FETCH_ALL_USERS_CUSTOMER_REQUEST",
    FETCH_ALL_CUSTOMER_SUCCESS: "FETCH_ALL_USERS_CUSTOMER_SUCCESS",
    FETCH_ALL_CUSTOMER_FAIL: "FETCH_ALL_USERS_CUSTOMER_FAIL",
};

export default ACTIONS;
