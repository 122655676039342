const ACTIONS = {

    FETCH_LOGISTIC_REQUEST: "FETCH_LOGISTIC_REQUEST",
    FETCH_LOGISTIC_SUCCESS: "FETCH_LOGISTIC_SUCCESS",
    FETCH_LOGISTIC_FAIL: "FETCH_LOGISTIC_FAIL",

    FETCH_LOGISTIC_PLAN_REQUEST: "FETCH_LOGISTIC_PLAN_REQUEST",
    FETCH_LOGISTIC_PLAN_SUCCESS: "FETCH_LOGISTIC_PLAN_SUCCESS",
    FETCH_LOGISTIC_PLAN_FAIL: "FETCH_LOGISTIC_PLAN_FAIL",
};

export default ACTIONS;
