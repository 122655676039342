import React, {createContext} from "react";
import Header from "./WebHeader";
import Footer from "./WebFooter";
import Helmet from 'react-helmet';
import ScrollToTop from '../../ScrollToTop';

const WebBase = ({children}) => {
    const timestamp = new Date().getTime();

    return (
        <div>
            <ScrollToTop>
                <Header />
                <div>{children}</div>
                <Footer />
                <Helmet>
                    <link href={`/css/mainstyle.css?v=${timestamp}`} rel='stylesheet' type="text/css" />
                    <link href={`/css/991max.css?v=${timestamp}`} rel='stylesheet' type="text/css" />
                    <link href={`/css/767max.css?v=${timestamp}`} rel='stylesheet' type="text/css" />
                    <link href={`/css/575max.css?v=${timestamp}`} rel='stylesheet' type="text/css" />
                </Helmet>
            </ScrollToTop>
        </div>
    );
};

export default WebBase;
