const ACTIONS = {
    CREATE_REQUEST: "CREATE_DEPARTMENT_REQUEST",
    CREATE_SUCCESS: "CREATE_DEPARTMENT_SUCCESS",
    CREATE_FAIL: "CREATE_DEPARTMENT_FAIL",

    FETCH_REQUEST: "FETCH_DEPARTMENT_REQUEST",
    FETCH_SUCCESS: "FETCH_DEPARTMENT_SUCCESS",
    FETCH_FAIL: "FETCH_DEPARTMENT_FAIL",

    UPDATE_REQUEST: "UPDATE_DEPARTMENT_REQUEST",
    UPDATE_SUCCESS: "UPDATE_DEPARTMENT_SUCCESS",
    UPDATE_FAIL: "UPDATE_DEPARTMENT_FAIL",

    DELETE_REQUEST: "DELETE_DEPARTMENT_REQUEST",
    DELETE_SUCCESS: "DELETE_DEPARTMENT_SUCCESS",
    DELETE_FAIL: "DELETE_DEPARTMENT_FAIL",
};

export default ACTIONS;
